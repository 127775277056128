import React, {Component, useContext, useEffect, useState} from 'react';
import request from 'superagent';
import './App.css';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import {Container, Row, Col} from 'react-bootstrap';

import {
  BrowserRouter as Router,
  Route, Routes,
  Link,
  useParams, useNavigate
} from 'react-router-dom'


//import TopicTransfer from './TopicTransfer';



// I am a bit tired to use the "property drilling" and the fact that 
// navigating to a URL does not really pass parameters easily.
// This section contains global variables to pass the state between
// components

// Used for modifying a pattern
var globalPattern = null;
window.globalTopic = null;

const LoginContext = React.createContext(null);

class HomeMenu extends Component {
  render() {

  return <div>

    <TopMenu/>
   
    <Alert variant="success">Logged on: {String(this.props.isLoggedIn)}</Alert>

    <h2>Home - <Link to="/topics">Media Monitoring topics</Link></h2>
    <div><Link to="/login">Login</Link></div>
    <div><Link to="/register">Register</Link></div>
    <div><Link to="/topic/create">Create Topic</Link></div>
    <div><Link to="/pattern/create">Create Pattern</Link></div>
  </div>
  }
}

class Home extends Component {    
  render() {    
    return <Route path="/" exact render={(props) => <HomeMenu isLoggedIn={this.props.isLoggedIn} handleLogin={this.props.handleLogin}/>}/>    
  }
}

class MediaMeteorRouter extends Component {


  constructor() {
    super();    
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      isLoggedIn: false
    }
  }

  componentDidMount() {

    // Check if the user is logged on and set the state accordingly
    request
        .get(process.env.REACT_APP_API_SERVER + "/API/user/checkloggedonstatus")
        .set('Accept', 'application/json')
        .withCredentials()
        .then(res => {
            if (res.unauthorized) {
              this.handeLogin(false);
            } else {
              if(res.body.success) {
                this.handleLogin(true);
              } else {
                this.handleLogin(false);
              }
            }
          }
 
        );
  }

  handleLogin(isLoggedIn) {
    this.setState({isLoggedIn: isLoggedIn});
  }

  render() {

  return <Router>
    <div>

        <Routes>
          {/* <Route path="/" element={(props) => <Home isLoggedIn={this.state.isLoggedIn}/>}/> */}
          <Route path="/" element={<HomeMenu isLoggedIn={this.state.isLoggedIn}/>}/>
          <Route path="/topics" element={<PublicTopics/>}/>
          <Route path="/mytopics" element={<MyTopics/>}/>
          <Route path="/register" element={<Register/>}/>

          {/* <Route path="/login" render={(props) => <Login isLoggedIn={this.state.isLoggedIn} handeLogin={this.handleLogin} />}/> */}
          
          <Route path="/login" element = {                  
              <Login isLoggedIn={this.state.isLoggedIn} handeLogin={this.handleLogin}/>              
          } />
        
          <Route path="/topic/create" element={<TopicCreate/>}/>      
          
          <Route path="/pattern/create/:topicid" element={<PatternCreate/>}/>
          <Route path="/pattern/edit/:patternid/:topicid" element={<PatternEdit/>}/>
          <Route path="/topic/delete/:topicid" element={<TopicDelete/>}/>
          <Route path="/topic/edit/:topicid" element={<TopicEdit/>}/>
          <Route path="/topic/transfer/:topicid" element={<TopicTransfer/>}/>
          <Route path="/topic/:id/patterns" element={<TopicPatterns/>} />
          <Route path="/topic/:id/:showall?" element={<Topic/>}/>        
        </Routes>      
      
    </div>
  </Router>
  }
}
export default MediaMeteorRouter

function Topic() {
  
  const [topic, setTopic] = useState(null);
  const [links, setLinks] = useState([]);
  let {id, showall} = useParams();

  function updateFromServer(id, showall) {

    var apiname = "topicrecent";
    if (showall === "true") {
      apiname = "topic";      
    } 
    console.log(apiname);


    request
        .get(process.env.REACT_APP_API_SERVER + "/API/" + apiname + "/" + id)
        .set('Accept', 'application/json')
        .withCredentials()
        .end((err, res) => {
          console.log(res.body);
          setLinks(res.body.links);
          setTopic(res.body.topic);
          window.globalTopic = res.body.topic;

          console.log("global topic");
          console.log(window.globalTopic);
        });

  }

  useEffect(() => {
    updateFromServer(id, showall);
  }, [id, showall]);


  var showAll = showall == "true";
  var urlid = "/topic/" + id;
  var label = "Recent Only";
  if (!showAll) {
    urlid += "/true";
    label = "All";
  }


  if (topic == null) return(<div/>);
      return (
        <div>
        <TopMenu TopicID={topic.TopicID}/>
<Container>     
  <Row>   
    <Col xs={2}/>
    <Col xs={50} >
      
        <div><Link to={{pathname: urlid}}>{label}</Link> | <a href={process.env.REACT_APP_API_SERVER + "/feed/" + topic.TopicID}>Feed</a></div>
        <h3>Topic {topic.TopicTitle}</h3>
        <h4>{topic.TopicDescription}</h4>
        ({showAll ? "Showing all items" : "Showing recent items only"})
      <ul>
        {links.map(l => {return <MatchLink link={l}/>}) } 
      </ul>
      <hr/>
      <Link to={{pathname: urlid}}>{label}</Link> | <a href={process.env.REACT_APP_API_SERVER + "/feed/" + topic.TopicID}>Feed</a>
    
    </Col>
    <Col/>
    </Row>
    </Container>  
    </div>
    )
  
}



function MatchLink(props) {
  var z = props.link.Context.split(props.link.MatchText).join("<strong>" + props.link.MatchText + "</strong>");
  return(<li><a href={props.link.LinkURL} target="_new">{props.link.Title}</a> ({props.link.LinkDateFormatted})<br/><div dangerouslySetInnerHTML={{__html:z}}/></li>)
}


class PublicTopics extends Component {

  constructor() {
    super();
    this.state = {
      topics: [],
    }

    // this allows a button to reload the details from API
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount() {

    request
        .get(process.env.REACT_APP_API_SERVER + "/API/index")
        .set('Accept', 'application/json')
        .end((err, res) => {
          this.setState({topics: res.body.topics});
        });
    
  }

  render() {
    return (      
      <div className="App">
        <TopMenu/>
        <ReloadIndexButton handler={this.componentDidMount}/>
        {this.state.topics.length == 0 &&
        <p className="App-intro">
Loading...        
        </p>}
        <ul>
          {this.state.topics.map((t) => {            
            return <TopicLink key={t.TopicID} topic={t}/>
          })}

        </ul>

        <ReloadIndexButton handler={this.componentDidMount}/>
      </div>
      
    );
  }
}


function ReloadIndexButton(props) {
  return (<Button onClick={props.handler} href="#">Reload</Button>)
}

function TopicLink(props) {

var showall = props.topic.RecentLinksCount ?  "" : "/true";
  
  return (
    <li>
      <Link to={"/topic/"+props.topic.TopicID + showall}>{props.topic.TopicTitle}</Link> ({props.topic.RecentLinksCount}/{props.topic.TopicCount})
    </li>
  )
}


class Register extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      email: '',
    };
  }

  onChange = (e) => {
    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { username, password, email } = this.state;

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/user/register")
      .send({ username: username,
              password: password,
              email: email})
      .set('Accept', 'application/json')
      .then(res => {
        alert('yay got ' + JSON.stringify(res.body));
   });

  }

  render() {
    const { username, password, email } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
      <div>
        Username <input
          type="text"
          name="username"
          value={username}
          onChange={this.onChange}
        />
        </div>
        <div>Password <input
          type="text"
          name="password"
          value={password}
          onChange={this.onChange}
        /></div>
        <div>Email <input
          type="text"
          name="email"
          value={email}
          onChange={this.onChange}
        /></div>
        <div>
        <button type="submit">Register</button>
        </div>
      </form>
    );
  }
}

function Login (props) {

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameInput = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    // get our form data out of state
    //const { username, password } = this.state;

    request         
      .post(process.env.REACT_APP_API_SERVER + "/API/user/login")          
      .send({ username: username,
              password: password
              })      
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {
        if(res.body.success) {
          props.handeLogin(true);
          console.log(props);
          navigate('/');          
        } else {
          alert(res.body.error);
        }

   });

  }

  
    return (
      <div>
        <TopMenu/>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control name="username" type="text" placeholder="Enter username" value={username} onChange={handleUsernameInput}/>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="Enter password" value={password} onChange={handlePasswordInput}/>
        </Form.Group>


      
      <div>
        {/* Debug: logged in: {String(this.props.isLoggedIn)} */}
        </div>


      <Button variant="primary" type="submit">
        Submit
      </Button>
      </Form>
      </div>
    );
  
}

function TopicCreate () {

  const navigate = useNavigate();

  const[TopicTitle, setTopicTitle] = useState('');
  const[TopicDescription, setTopicDescription] = useState('');


  const handleTopicTitleInput = (e) => {
    setTopicTitle(e.target.value);
  }

  const handleTopicDescriptionInput = (e) => {
    setTopicDescription(e.target.value);
  }


  const onSubmit = (e) => {
    e.preventDefault();

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/topic/create")
      .send({ TopicTitle: TopicTitle,
              TopicDescription: TopicDescription
              })
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {
        var topicid = res.body.newTopicID;
        navigate('/pattern/create/' + topicid);
      });

  }

    return (
      <div>
        <TopMenu/>
      <form onSubmit={onSubmit}>
      <div>
        Topic <input
          type="text"
          name="TopicTitle"
          value={TopicTitle}
          onChange={handleTopicTitleInput}
        />
        </div>
        <div>Description <input
          type="text"
          name="TopicDescription"
          value={TopicDescription}
          onChange={handleTopicDescriptionInput}
        /></div>
        <div>
        <button type="submit">Create Topic</button>
        </div>
      </form>
      </div>
    );

}

function PatternCreate() {

  const navigate = useNavigate();

  const [Pattern, setPattern] = useState('');
  const [PatternDescription, setPatternDescription] = useState('');
  // const [TopicID, setTopicID] = useState(0);

  let {topicid} = useParams();  

  const onSubmit = (e) => {
    e.preventDefault();

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/pattern/create")
      .send({ Pattern: Pattern,
              PatternDescription: PatternDescription,
              TopicID: parseInt(topicid, 10)
              })
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {        
        var patternid = res.body.newPatternID;
        // this.props.history.push({pathname: '/topic/' + topicid + "/true"});
        navigate('/topic/' + topicid + "/true");

   });

  }

  const handlePatternInput = (e) => {
    setPattern(e.target.value);
  }

  const handlePatternDescriptionInput = (e) => {
    setPatternDescription(e.target.value);
  }



    return (
      <div>
        <TopMenu/>
      <form onSubmit={onSubmit}>
      <div class="row mb-3">      
        <label for="pat" class="col-sm-2 col-form-label">Pattern (regex)</label>
        <div class="col-sm-6">
         <input id="pat"
          type="text"
          name="Pattern"
          value={Pattern}
          onChange={handlePatternInput}
          class="form-control form-control-sm"
        />
        </div>
        </div>

        <div class="row mb-3">
          <label for="desc" class="col-sm-2 col-form-label">Description</label> 
          <div class="col-sm-6">
            <input id="desc"
              type="text"
              name="PatternDescription"
              value={PatternDescription}
              onChange={handlePatternDescriptionInput}
              class="form-control form-control-sm"
            />
          </div>
        </div>

        <input
          type="hidden"
          name="TopicID"
          value={topicid}
        />

        <div>
        <button type="submit">Create Pattern</button>
        </div>
      </form>
      </div>
    );
  }



function PatternEdit() {

  const navigate = useNavigate();

  let {patternid} = useParams();

   if (globalPattern == null) {
     navigate('/topics');
     return;
  }



  const [Pattern, setPattern] = useState(globalPattern.Pattern);
  const [PatternDescription, setPatternDescription] = useState(globalPattern.PatternDescription);
  const [PatternID, setPatternID] = useState(patternid);
  const [TopicID, setTopicID] = useState(globalPattern.TopicID);


  const onSubmit = (e) => {
    e.preventDefault();

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/pattern/edit")
      .send({ Pattern: Pattern,
              PatternDescription: PatternDescription,
              TopicID: parseInt(TopicID,10),
              PatternID: parseInt(PatternID,10)
              })
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {        
        var patternid = res.body.newPatternID;
        navigate('/topic/' + TopicID + "/patterns");
   });

  }

  const handlePatternInput = (e) => {
    setPattern(e.target.value);
  }

  const handlePatternDescriptionInput = (e) => {
    setPatternDescription(e.target.value);
  }


    return (
      <div>
        <TopMenu/>
      <form onSubmit={onSubmit}>
      <div>
        Pattern (regex) <input
          type="text"
          name="Pattern"
          value={Pattern}
          onChange={handlePatternInput}
        />
        </div>
        <div>Description <input
          type="text"
          name="PatternDescription"
          value={PatternDescription}
          onChange={handlePatternDescriptionInput}
        /></div>

        <input
          type="hidden"
          name="TopicID"
          value={TopicID}          
        />

        <input
          type="hidden"
          name="PatternID"
          value={PatternID}          
        />


        <div>
        <button type="submit">Edit Pattern</button>
        </div>
      </form>
      </div>
    );
}

function TopMenu(props) {

  return <Navbar bg="light" expand="lg" >
  
  <Nav className="flex-row">
  <Navbar.Brand href="/">Media Monitor</Navbar.Brand>      
  <Nav className="flex-row">
    <Nav.Link className="p-2" href="/topics">Public Topics</Nav.Link>  
    <Nav.Link className="p-2" href="/mytopics">My Topics</Nav.Link>  
  </Nav>
  </Nav>
  
  
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
  
      <NavDropdown title="Manage" id="basic-nav-dropdown">
        <NavDropdown.Item href="/topic/create">Add new topic</NavDropdown.Item>



        {typeof(props.TopicID) !== 'undefined' &&
          <NavDropdown.Item href={"/topic/" + props.TopicID + "/patterns"}>List patterns</NavDropdown.Item>
        }
        {typeof(props.TopicID) !== 'undefined' &&
        <NavDropdown.Item href={"/topic/delete/" + props.TopicID}>Delete topic</NavDropdown.Item>
        }
        {typeof(props.TopicID) !== 'undefined' &&
        <NavDropdown.Item href={"/topic/edit/" + props.TopicID}>Edit topic</NavDropdown.Item>
        }
        {typeof(props.TopicID) !== 'undefined' &&<NavDropdown.Divider />}
        {typeof(props.TopicID) !== 'undefined' &&
        <NavDropdown.Item href={"/topic/transfer/" + props.TopicID}>Transfer topic</NavDropdown.Item>
        }         
      </NavDropdown>


      <Nav.Link href="/login">Login</Nav.Link>
      <Nav.Link href="/register">Register</Nav.Link>
    </Nav>
  </Navbar.Collapse>  
</Navbar>;
}

class MyTopics extends Component {

  constructor() {
    super();
    this.state = {
      loaded: false,
      topics: [],
    }

    // this allows a button to reload the details from API
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount() {
 
    request
        .get(process.env.REACT_APP_API_SERVER + "/API/user/mytopics")
        .set('Accept', 'application/json')
        .withCredentials()
        .end((err, res) => {
          this.setState({topics: res.body.topics
            , loaded: true});
        });
    
  }

  render() {
    return (      
      <div className="App">
        <TopMenu/>
        <ReloadIndexButton handler={this.componentDidMount}/>
        {this.state.topics.length == 0 && this.state.loaded==false &&
        <p className="App-intro">
Loading...        
        </p>}
        {this.state.topics.length == 0 && this.state.loaded==true &&
        <p className="App-intro">
No topics add yet..
        </p>}
        <ul>
          {this.state.topics.map((t) => {            
            return <TopicLink key={t.TopicID} topic={t}/>
          })}

        </ul>

        <ReloadIndexButton handler={this.componentDidMount}/>
      </div>
      
    );
  }
}



class TopicDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {      
      topicID: props.match.params.topicid,
      topicTitle: ""
    }

  }

  updateFromServer() {
        
    var apiname = "topic";

    request
        .get(process.env.REACT_APP_API_SERVER + "/API/" + apiname + "/" + this.state.topicID)
        .set('Accept', 'application/json')
        .withCredentials()
        .end((err, res) => {
          console.log(res.body);
          this.setState({            
            topicTitle: res.body.topic.TopicTitle
          });
        });
        
      }
  componentDidMount() {  
    this.updateFromServer();
  }



  render () {
    return <div>
      <TopMenu TopicID={this.state.topicID}/>
      Delete this topic {this.state.topicID} {this.state.topicTitle}?

     <Button onClick={this.onSubmit}>Delete topic!</Button>
      </div>

  }


  onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const {topicID} = this.state;

    request   
      .del(process.env.REACT_APP_API_SERVER + "/API/topic/delete/" + topicID) 
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {        
        this.props.history.push({pathname: '/mytopics'});
   });

  }

}



function TopicEdit() {

  const navigate = useNavigate();

  let {topicid} = useParams();

  const [TopicTitle, setTopicTitle] = useState("");
  const [TopicDescription, setTopicDescription] = useState("");

  function updateFromServer() {
        
    var apiname = "topic";

    request
        .get(process.env.REACT_APP_API_SERVER + "/API/" + apiname + "/" + topicid)
        .set('Accept', 'application/json')
        .withCredentials()
        .end((err, res) => {          
          setTopicTitle(res.body.topic.TopicTitle);
          setTopicDescription(res.body.topic.TopicDescription);
        });
        
  }
      
  useEffect(() => {
    updateFromServer();
  }, [topicid]);

  
  const handleTopicTitleInput = (e) => {
    setTopicTitle(e.target.value);
  }

  const handleTopicDescriptionInput = (e) => {
    setTopicDescription(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/topic/edit")
      .send({ 
              TopicID: Number(topicid),
              TopicTitle: TopicTitle,
              TopicDescription: TopicDescription
              })
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {
        navigate('/topic/' + topicid);
   });

  }


    return (
      <div>
        <TopMenu/>
      <form onSubmit={onSubmit}>
      <div>
        Topic <input
          type="text"
          name="TopicTitle"
          value={TopicTitle}
          onChange={handleTopicTitleInput}
        />
        </div>
        <div>Description <input
          type="text"
          name="TopicDescription"
          value={TopicDescription}
          onChange={handleTopicDescriptionInput}
        /></div>
        <div>
        <button type="submit">Save updates</button>
        </div>
      </form>
      </div>
    );
}



function TopicPatterns () {

  const [patterns, setPatterns] = useState([]);
  const [topicID, setTopicID] = useState(0);
  const [error, setError] = useState("");

  let {id} = useParams();

  useEffect(() => {

    request
    .get(process.env.REACT_APP_API_SERVER + "/API/topic/"+ id +"/patterns")
    .set('Accept', 'application/json')
    .withCredentials()
    .end((err, res) => {      
      setPatterns(res.body.patterns);
      setTopicID(res.body.TopicID);
    });
    
  }, [id]);

  function handlePattern(op, id, pat, desc) {
    if (op == "del") {

      setPatterns(patterns.filter(
        function(pattern) {
          console.log("id is to be deleted " + id);
          return pattern.PatternID != id
        }
      ));

    } else if (op == "edit") {

    } else if (op == "err") {
      setError(id);
    }
  }  
  
    const handleDismiss = () => setError("");

    return (
      <div className="App">
        <TopMenu/>        
        <Alert show={error != ""} variant="danger" dismissible onClose={handleDismiss}>
          {error}
        </Alert>
        
        <table class="table table-bordered">
          <tbody>
          {patterns.map((t) => {            
            console.log(t);
            return <Pattern key={t.PatternID} handlePattern={handlePattern} pattern={t}/>
          })}
          </tbody>
        </table>
        
        <Button href={"/pattern/create/" + topicID}>Add another pattern</Button>
      </div>      
   
    );
  }


const Pattern = ({pattern, handlePattern}) => {

    return <tr><td class="w-25">
              {pattern.Pattern}</td>
              <td class="w-50">{pattern.PatternDescription}</td>
              <td class="w-25"><DeletePattern pattern={pattern} handlePattern={handlePattern}/> <EditPatternButton pattern={pattern} handlePattern={handlePattern}/></td>
          </tr>

}

const DeletePattern  =( {pattern, handlePattern}) => {

//  let {pattern, handlePattern} = useParams();

  const handle = (e) => {


    // Check if the user is logged on and set the state accordingly
    request
        .del(process.env.REACT_APP_API_SERVER + "/API/pattern/delete/" + pattern.PatternID)
        .set('Accept', 'application/json')
        .withCredentials()
        .then(res => {
              if(res.body.success) {              
                handlePattern("del", pattern.PatternID);                
              } else {
                console.log(res);
                handlePattern("err", "Cannot delete the pattern");                              
              }

          }
 
        ).catch(err => {
          console.log(err);
          handlePattern("err", "Cannot delete the pattern, error occured");
          }
        );

    console.log(pattern);
  }

  
    return <Button onClick={handle}>Delete</Button>
  
  
}


// const Pattern = ({pattern, handlePattern}) => {
const EditPatternButton = ({pattern}) => {

  const navigate = useNavigate();
  // let {pattern} = useParams();

  const handle = (e) => {    

    console.log(pattern);    
    globalPattern = pattern;    
    navigate('/pattern/edit/' + pattern.PatternID + "/" + pattern.TopicID);
  }
  
  return <Button onClick={handle}>Edit</Button>
}

//const EditPatternButton = withRouter(EditPatternButton_);


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
 //   logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

function TopicTransfer () {

  const public1 = ["<public>"];

  const navigate = useNavigate();

  let {topicid} = useParams();    

  const [TopicID, setTopicID] = useState(topicid);
  const [TopicTitle, setTopicTitle] = useState("Topic Title retrieval not implemented");
  const [TopicOwner, setTopicOwner] = useState(public1[0]);
  const [Users, setUsers] = useState([]);

  function updateFromServer() {
                
    request
        .get(process.env.REACT_APP_API_SERVER + "/API/" + "user/users")
        .set('Accept', 'application/json')
        .withCredentials()
        .end((err, res) => {
          setUsers(res.body);
        });
        
      }

      useEffect(() => {
        updateFromServer();
      }, [topicid]);  

  const handleTopicOwnerInput = (e) => {
    setTopicOwner(e.target.value);
  }


  const onSubmit = (e) => {
    e.preventDefault();

    request   
      .post(process.env.REACT_APP_API_SERVER + "/API/topic/transfer")
      .send({ 
              TopicID: Number(TopicID),
              UserName: TopicOwner,              
              })
      .set('Accept', 'application/json')
      .withCredentials()
      .then(res => {        
        navigate({pathname: '/topic/' + topicid});
      });


  }  


    var publicAndUsers = public1.concat(Users);

    let userslist = publicAndUsers.map((item, i) => {
      return (<option value={item}>{item}</option>)
    });

    return (
      <div>
        <TopMenu/>
      <form onSubmit={onSubmit}>
      <div>
        Topic {TopicTitle}
        </div>
        <div>New user 
          <select name="TopicOwner"
            value={TopicOwner}
            onChange={handleTopicOwnerInput}
          >

          {userslist}
            
          </select>
        </div>
        <div>
        <button type="submit">Save updates</button>
        </div>
      </form>
      </div>
    );
  }



